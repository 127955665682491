import { configureStore } from '@reduxjs/toolkit';
import authRedux from './Slice/auth';
import userRedux from './Slice/user';

export const store = configureStore({
    reducer: {
        auth: authRedux,
        user: userRedux,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
