import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { default as dayjs } from 'dayjs';
import Cookies from 'js-cookie';

//get cookies
const tokenCookie = Cookies.get('expjwt');
const typeCookie = Cookies.get('type');
const expirationCookie = Cookies.get('cookieExpiration');

//domain
const domain = import.meta.env.VITE_APP_DOMAIN;
const expires = 20 / 60 / 24;

interface authState {
    token: string;
    cookieTimer: string;
    type: string;
}

const initialState: authState = {
    token: tokenCookie || '',
    cookieTimer: expirationCookie || '',
    type: typeCookie || '',
};

const handleRemoveCookie = () => {
    Cookies.remove('expjwt', { domain });
    Cookies.remove('type', { domain });
    Cookies.remove('cookieExpiration', { domain });
    Cookies.remove('userView', { domain });
};

const handleSaveCookies = (state: authState) => {
    console.log("saving cookies")
    Cookies.set('expjwt', state.token, { domain, expires });
    Cookies.set('type', state.type, {
        domain,
        expires,
    });
    Cookies.set('cookieExpiration', state.cookieTimer, {
        expires,
        domain,
    });
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth: (state, action) => {
            const { token, type } = action.payload;

            const cookieTimer = dayjs().add(8, 'minutes').toISOString();
            state.token = token;
            state.cookieTimer = cookieTimer;
            state.type = type;
            handleSaveCookies(state);
        },
        changeAuth: (state, action) => {
            const { token } = action.payload;
            const cookieTimer = dayjs().add(8, 'minutes').toISOString();
            state.token = token;
            state.cookieTimer = cookieTimer;
            handleSaveCookies(action.payload);
        },
        logoutAuth: state => {
            state.token = '';
            state.cookieTimer = '';
            state.type = '';
            handleRemoveCookie();
        },
    },
});

export const { setAuth, changeAuth, logoutAuth } = authSlice.actions;
export const AuthSelector = (state: RootState) => state.auth;
export default authSlice.reducer;
